import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import Loading from "../loading/Loading";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const mytoken = localStorage.getItem("token");

  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await axios.get(`https://testapi.web-builderit.com/api/me/${mytoken}`, {
          headers: {
            Authorization: `Bearer ${mytoken}`,
          },
        });

        if (response.data.user) {
          setUser(response.data.user);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.log("Token validation failed:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    if (mytoken) {
      getToken();
    } else {
      setLoading(false);
    }
  }, [mytoken]);

  if (loading) {
    return <Loading />;
  }

  if (!user || user.role !== "admin") {
    localStorage.removeItem("token"); // Clear invalid token
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
