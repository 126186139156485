import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MedicineAdd = () => {

    const [categories, setCategory] = useState([]);
    const [company, setCompany] = useState([]);
    const [totalPrice,setTotalPurchasePrice] = useState(0);
    const [totalPricePerPice,setTotalPurchasePricePerPice] = useState(0);

    const token = localStorage.getItem('token')


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://testapi.web-builderit.com/api/medicine/add', {
                medicine_name: event.target.medicine_name.value,
                category_id: event.target.category_id.value,
                company_id: event.target.company_id.value,
                purchase_date: event.target.purchase_date.value,
                purchase_price_pice: event.target.purchase_price_pice.value ? parseFloat(event.target.purchase_price_pice.value) : 0,
                purchase_price: event.target.purchase_price.value ? parseFloat(event.target.purchase_price_pice.value) *  parseInt(event.target.stock.value) : 0,
                selling_price: event.target.selling_price.value ? parseFloat(event.target.selling_price.value) : 0,
                selling_price_pice: event.target.selling_price_pice.value ? parseFloat(event.target.selling_price_pice.value) : 0,
                expired_date: event.target.expired_date.value,
                stock: event.target.stock.value,
                toal_stock: event.target.toal_stock.value,
            }, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });


            console.log(response.data)
            if (response.data?.success) {
                toast.success(response.data.msg)
            } else{
                toast.error(response.data.msg)
            }



        } catch (error) {
            console.log(error);

        }

    }


    const getCompanyCategory= async () => {

        try {
            const response = await axios.get(`https://testapi.web-builderit.com/api/get/company/category`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });
            
            if(response?.data?.companyes){
                setCompany(response?.data?.companyes)
            }
            if(response?.data?.categories){
                setCategory(response?.data?.categories)
            }
        

        } catch (error) {
            console.log(error);

        }

    }

    useEffect(()=>{
        getCompanyCategory()
    },[])

    const totalPurchasePrice = (quantity) => {
       const price = parseFloat(quantity) * parseFloat(totalPricePerPice)
       setTotalPurchasePrice(price)
    }


    return (
        <div className='container'>
            <ToastContainer />
            <div className='card p-4 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <h2 className="text-secondary">Medicine Add</h2>
                    <div>
                        <Link to="/medicine" className='btn btn-primary'> Manage Medicine</Link>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='row gy-3'>
                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Medicine Name</label>
                                <Form.Control type="text" name='medicine_name' placeholder="Medicine Name" required />
                            </Form.Group>
                        </div>

                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Company Name</label>
                                <select className="form-select" name='company_id' required>
                                    <option selected>Select Medicine Company</option>
                                    {company?.map(item => {
                                        return <option key={item?.id} value={item?.id}>{item?.company_name}</option>
                                    })}
                                    
                                </select>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Category</label>
                                <select className="form-select" name='category_id' required>
                                    <option selected>Select Medicine Category</option>
                                    {
                                        categories?.map(item => {
                                            return <option key={item?.id} value={item?.id}>{item?.category_name}</option>
                                        })
                                    }
                                </select>
                       
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Date</label>
                                <Form.Control type="date" name='purchase_date' placeholder="Purchase Date" required />
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Price Per Medicine</label>
                                <Form.Control type="number" name='purchase_price_pice' placeholder="Purchase Price" step="0.001"  onChange={(e)=>{setTotalPurchasePricePerPice(e.target.value)}}/>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Stock</label>
                                <Form.Control type="number" name='toal_stock' placeholder="Total Quantity" required />
                            </Form.Group>

                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Quantity</label>
                                <Form.Control type="number" name='stock' placeholder="Quantity" onChange={(e)=>{totalPurchasePrice(e.target.value)}} required />
                            </Form.Group>

                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Purchase Price</label>
                                <Form.Control type="number" name='purchase_price' placeholder="Purchase Price" value={totalPrice} step="0.001" readOnly/>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Selling Price</label>
                                <Form.Control type="number" name='selling_price' placeholder="Selling Price" step="0.001"/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Selling Price Per Medicine</label>
                                <Form.Control type="number" name='selling_price_pice' placeholder="Selling Price" step="0.001"/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Expired Date</label>
                                <Form.Control type="date" name='expired_date' placeholder="Medicine Date" required />
                            </Form.Group>

                        </div>


                    </div>


                    <Button variant="primary mt-4" type="submit">
                        Submit
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default MedicineAdd;