import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MedicineUpdate = () => {


    const [medicine, setMedicine] = useState({});
    const [categories, setCategory] = useState([]);
    const [companyes, setCompany] = useState([]);

    const [totalPrice,setTotalPurchasePrice] = useState(0);
    const [totalPricePerPice,setTotalPurchasePricePerPice] = useState(0);

    const token = localStorage.getItem('token')
    let { id } = useParams();

    const getSpecificMedicine = async () => {

        try {
            const response = await axios.get(`https://testapi.web-builderit.com/api/medicine/getitem/${id = id}`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            setMedicine(response.data?.medicine);
            setCategory(response.data?.categories);
            setCompany(response.data?.companyes);
            setTotalPurchasePricePerPice(response.data?.medicine?.purchase_price_pice);
            const totalprice = parseFloat(response.data?.medicine?.stock) * parseFloat(response.data?.medicine?.purchase_price_pice)
            setTotalPurchasePrice(totalprice)

        } catch (error) {
            console.log(error);

        }

    }

    useEffect(() => {
        getSpecificMedicine()
    }, [])


    const handleUpdate = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://testapi.web-builderit.com/api/medicine/edit', {
                medicine_name: event.target.medicine_name.value,
                category_id: event.target.category_id.value,
                company_id: event.target.company_id.value,
                purchase_date: event.target.purchase_date.value,
                purchase_price_pice: event.target.purchase_price_pice.value ? parseFloat(event.target.purchase_price_pice.value) : 0,
                purchase_price: event.target.purchase_price.value ? parseFloat(event.target.purchase_price_pice.value) *  parseInt(event.target.stock.value) : 0,
                selling_price: event.target.selling_price.value ? parseFloat(event.target.selling_price.value) : 0,
                selling_price_pice: event.target.selling_price_pice.value ? parseFloat(event.target.selling_price_pice.value) : 0,
                expired_date: event.target.expired_date.value?event.target.expired_date.value:0,
                stock: event.target.stock.value,
                toal_stock: event.target.toal_stock.value,
                id: parseInt(id),
            }, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            if (response.data.success) {
                toast.success(response.data.msg)
            } else if (!response.data.success) {
                toast.error(response.data.msg)
            }



        } catch (error) {
            console.log(error);

        }

    }

    const totalPurchasePrice = (quantity) => {
        const price = parseFloat(quantity) * parseFloat(totalPricePerPice)
        setTotalPurchasePrice(price)
     }
 

    return (
        <div className='container'>
            <ToastContainer />
            <div className='card p-4 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <h2 className="text-secondary">Medicine Update</h2>
                    <div>
                        <Link to="/medicine" className='btn btn-primary'> Manage Medicine</Link>
                    </div>
                </div>
                <form onSubmit={handleUpdate}>
                    <div className='row gy-3'>
                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Medicine Name</label>
                                <Form.Control type="text" defaultValue={medicine?.medicine_name} name='medicine_name' placeholder="Medicine Name" />
                            </Form.Group>
                        </div>
                        
                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Company</label>
                                <select className="form-select" name='company_id' aria-label=".form-select-lg example" required>
                                    {
                                        companyes?.map(company => {
                                            return <option key={company?.id} value={company?.id} selected={company?.id == medicine?.company_id}>{company?.company_name}</option>
                                        })
                                    }
                                    
                                </select>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Category</label>
                                <select className="form-select" name='category_id' aria-label=".form-select-lg example"  required>
                                    {
                                        categories?.map(category => {
                                            return <option key={category?.id} value={category?.id} selected={category?.id == medicine?.category_id}>{category?.category_name}</option>
                                        })
                                    }
                                    
                                </select>
                       
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Date</label>
                                <Form.Control type="date" defaultValue={medicine?.purchase_date} name='purchase_date' placeholder="Purchase Date" />
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Price Per Medicine</label>
                                <Form.Control type="number" defaultValue={medicine?.purchase_price_pice} name='purchase_price_pice' placeholder="Price"  step="0.001"  onChange={(e)=>{setTotalPurchasePricePerPice(e.target.value)}}/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Stock</label>
                                <Form.Control type="number" defaultValue={medicine?.toal_stock} name='toal_stock' placeholder="Quantity" />
                            </Form.Group>

                        </div>
                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Quantity</label>
                                <Form.Control type="number" defaultValue={medicine?.stock}  onChange={(e)=>{totalPurchasePrice(e.target.value)}} name='stock' placeholder="Quantity" />
                            </Form.Group>

                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Purchase Price</label>
                                <Form.Control type="number" value={totalPrice} name='purchase_price' placeholder="Price"  step="0.001" readOnly/>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Selling Price</label>
                                <Form.Control type="number" defaultValue={medicine?.selling_price} name='selling_price' placeholder="Price" />
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Selling Price per Medicine</label>
                                <Form.Control type="number" defaultValue={medicine?.selling_price_pice} name='selling_price_pice' placeholder="Price" step="0.001"/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Expired Date</label>
                                <Form.Control type="date" defaultValue={medicine?.expired_date} name='expired_date' placeholder="Medicine Date" />
                            </Form.Group>

                        </div>

                       

                    </div>


                    <Button variant="primary mt-4" type="submit">
                        Update
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default MedicineUpdate;