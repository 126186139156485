import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import Loading from "../loading/Loading";

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const mytoken = localStorage.getItem("token");

  useEffect(() => {
    const getToken = async () => {
      if (!mytoken) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://testapi.web-builderit.com/api/me/${mytoken}`, {
          headers: {
            Authorization: `Bearer ${mytoken}`,
          },
        });

        if (response.data.user) {
          setUser(response.data.user);
        } else {
          localStorage.removeItem("token");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        localStorage.removeItem("token"); // Clear the token if there's an error
      } finally {
        setLoading(false);
      }
    };

    getToken();
  }, [mytoken]);

  if (loading) {
    return <Loading />;
  }

  if (user && (user.role === 'user' || user.role === 'admin')) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
